<template>
<div class='titlu'>
    <el-row>
        <el-col :span="22" style="text-align:center">
            <h1 style="margin-left:100px; margin-bottom:0px"> <span @click="titleClicked" style="cursor:pointer;">{{Titlu.toUpperCase()}}</span></h1>
        </el-col>
        <el-col :span="2" v-if="AdaugaVisible">
            <el-button type='primary' class='btn-adauga' @click="on_add_clicked" icon='el-icon-plus'> {{AdaugaText}} </el-button>
        </el-col>

        <!-- export excel -->
        <el-col :span="2" v-if="exportExcel">
            <el-button style="margin-top:12px; margin-left:-45px" type='warning' @click="onExportClick" > Export Excel Clienti </el-button>
        </el-col>
        <!--  -->
    </el-row>
</div>
</template>

<script>

import settings from '@/backend/LocalSettings';

export default {
    name: 'TitluPagina',
    props:{
        Titlu: "",
        AdaugaText: "Adauga",
        AdaugaVisible: true,
        exportExcel: false
    },
    data () {
        return {
        }
    },
    methods:
        {
            on_add_clicked: function(){
                this.$emit('on_add_clicked');
            },
            titleClicked(){
                this.$emit('on_title_clicked');
            },
            onExportClick(){
                this.$emit('onExportClick');
            }
        }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">


</style>
